import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { getMe } from "../../redux/users";

const DonationBanner = ({ initiative, onClose }) => {
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!users.me) {
      dispatch(getMe());
    }
  }, [users.me]);

  const DONATION_BANNER_MESSAGES = {
    ended: `Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet. 
      Klicke einfach auf den Link in Deinem Postfach um zukünftig Neuigkeiten 
      zu erhalten.`,
    support: `Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet. 
      Klicke einfach auf den Link in Deinem Postfach und wir informieren dich 
      sobald es mit der Unterschriftensammlung los geht.`,
    petition: `Wir haben Dir eine E-Mail mit einem Bestätigungslink zugesendet. 
      Klicke einfach auf den Link in Deinem Postfach um Deine Unterschrift 
      zu bestätigen. Andernfalls wird sie nicht gezählt.`,
  };

  const closeBanner = () => {
    if (onClose) { onClose() };

    setIsBannerOpen(false);
  };

  const isEnded =
    "ended" === initiative?.initiativeStatuses?.[initiative?.stage];
  const isSupport = "support" === initiative?.stage;
  const isPetition = "petition" === initiative.type;

  const displayBannerMessage = (isEnded || isSupport || isPetition) && !users?.me?.id;

  return (
    <>
      {displayBannerMessage && isBannerOpen && (
        <section className="bg-secondary py-4">
          <div className="container d-flex flex-md-nowrap flex-wrap">
            <div
              className="d-flex flex-grow-1 justify-content-end mb-2 pe-md-2 order-md-2"
              onClick={closeBanner}
              role="button"
            >
              <FontAwesomeIcon
                className="text-primary"
                icon={faTimes}
                size="lg"
              />
            </div>
            <div className="d-flex ps-md-4">
              <FontAwesomeIcon
                className="text-primary"
                icon={faEnvelope}
                size="4x"
              />
              <div className="mx-md-5 px-md-0 px-4">
                <h3 className="mb-3 text-primary ">
                  Vielen Dank! Bitte bestätige Deine E-Mail-Adresse.
                </h3>
                {isEnded && <p>{DONATION_BANNER_MESSAGES.ended}</p>}
                {isSupport && !isEnded && (
                  <p>{DONATION_BANNER_MESSAGES.support}</p>
                )}
                {isPetition && (
                  <p>{DONATION_BANNER_MESSAGES.petition}</p>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default DonationBanner;
