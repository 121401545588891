import api from "../../../helpers/api";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { useContext, useEffect, useState, useHistory } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { faChevronsLeft, faLink } from "@fortawesome/pro-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import DonationBanner from "../DonationBanner";
import heroInitiative from "../../../styles/images/hero-image.svg";
import heroPetition from "../../../styles/images/hero-image-petition.svg";
import { ThemeContext } from "../../../ThemeContext";
import { SignableSteps } from "../navigation";

const SocialShare = () => {
  const location = useLocation();
  let { id } = useParams();

  const isBannerDisplayed = location?.state?.isBannerDisplayed;

  const shareUrl =
    location.state && location.state.shareUrl
      ? location.state.shareUrl
      : `${window.location.origin}/${id}`;
  const [state, setState] = useState({ value: shareUrl, copied: false });
  const { trackEvent } = useMatomo();
  const [initiative, setInitiative] = useState({});
  const { setTheme } = useContext(ThemeContext);

  const CONTENT = {
    initiative: {
      hero: heroInitiative,
      shareText: `Ich habe gerade die Unterschriftenliste für die Initiative “${initiative.title}” heruntergeladen. Gehe jetzt auf {{site_url}}, um Deine Liste zu bekommen.`,
    },
    petition: {
      hero: heroPetition,
      shareText: `Ich habe soeben die Petition für die Kampagne “${initiative.title}” unterschrieben. Gehe jetzt auf {{site_url}}, falls du auch die Kampagne unterstützen willst!`
    }
  };

  const shareText = `${CONTENT?.[initiative.type]?.shareText} ${shareUrl}`;

  const handleClick = (medium, url) => {
    if ("clipboard" == medium) {
      setState({ value: state.value, copied: true });
    } else {
      window.open(url, "_blank");
    }
    trackEvent({
      category: "document",
      action: "shareInitiative",
    });
  };

  useEffect(() => {
    if (location.state?.initiative) {
      setInitiative(location.state.initiative);
      setTheme(location.state.initiative.type || "petition");
      return;
    }

    api.getInitiative(id, (_status, data) => {
      setInitiative(data);
      setTheme(data.type || "petition");
    });
  }, [location.state, id]);

  return (
    <div className="share">
      {!isBannerDisplayed && <DonationBanner initiative={initiative} />}
      {/* <div className="share-header">
        <div className="share-header-container d-flex justify-content-between">
          <h3 className="share-header-text text-secondary">
            Grundeinkommen für alle
          </h3>
          <Icon name={"icon-close"} />
        </div>
      </div> */}
      <div className="share-container">
        <div className="container-narrow-sm pt-5">
          <SignableSteps currentStep={3} pageCount={3} signable={initiative} isBannerDisplayed={isBannerDisplayed} />
        </div>
        <div className="hero hero-image">
          <div className="hero-container">
            <div className="hero-row">
              <div className="hero-body content">
                <h1 className="hero-title">
                  Teilen führt zu mehr Unterschriften
                </h1>
                <h4>
                  Informiere Familie und Freund:innen. So hilfst Du, mehr
                  Unterschriften zu sammeln!
                </h4>
                <div className="hero-button-container">
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={() =>
                      handleClick(
                        "facebook",
                        `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`
                      )
                    }
                  >
                    <FontAwesomeIcon
                      className="icon icon-left text-white"
                      icon={faFacebook}
                      size="lg"
                    />
                    Auf Facebook teilen
                  </button>
                  <CopyToClipboard
                    text={state.value}
                    onCopy={() => handleClick("clipboard")}
                  >
                    <button className="btn btn-lg btn-outline">
                      <FontAwesomeIcon
                        className="icon icon-left text-primary"
                        icon={faLink}
                        size="lg"
                      />
                      Link kopieren
                    </button>
                  </CopyToClipboard>
                  <button
                    className="btn btn-lg btn-outline"
                    onClick={() =>
                      handleClick(
                        "twitter",
                        `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText.replace("{{site_url}}", "@innn_it"))}`
                      )
                    }
                  >
                    <FontAwesomeIcon
                      className="icon icon-left text-primary"
                      icon={faTwitter}
                      size="lg"
                    />
                    An Follower twittern
                  </button>
                  <button
                    className="btn btn-lg btn-outline"
                    onClick={() =>
                      handleClick(
                        "email",
                        `mailto:?subject=innn.it&body=${encodeURIComponent(shareText.replace("{{site_url}}", "innn.it"))}`
                      )
                    }
                  >
                    <FontAwesomeIcon
                      className="icon icon-left text-primary"
                      icon={faEnvelope}
                      size="lg"
                    />
                    Per E-Mail senden
                  </button>
                  <button
                    className="btn btn-lg btn-outline -white"
                    onClick={() =>
                      handleClick(
                        "whatsapp",
                        `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText.replace("{{site_url}}", "innn.it"))}`
                      )
                    }
                  >
                    <FontAwesomeIcon
                      className="icon icon-left text-primary"
                      icon={faWhatsapp}
                      size="lg"
                    />
                    Per WhatsApp senden
                  </button>
                </div>
              </div>
              <div className="hero-img">
                <div className="hero-img-holder">
                  <img src={CONTENT?.[initiative.type]?.hero} alt="innn.it-img"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Link
            to={`/${id}`}
            className="text-primary"
          >
            <FontAwesomeIcon className="icon icon-left text-primary" icon={faChevronsLeft} size="sm"/>
            Zurück zur Petition <span className="text-black">{initiative.title}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SocialShare;
