import { useMatomo } from "@datapunt/matomo-tracker-react";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { faMessage, faQuestion } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AuthenticationContext } from "../../AuthenticationContext";
import useValidateStep from "../../custom-hooks/use-validate-step";
import api from "../../helpers/api";
import { ThemeContext } from "../../ThemeContext";
import NotFound from "../NotFound";
import Contact from "./components/Contact";
import Pagination from "./components/Pagination";
import StepComponent from "./steps/StepComponent";

const Index = () => {
  const defaultInitiative = {
    type: "petition",
    title: "",
    tag: 0,
    initiators: [],
    targets: [],
    content: "",
    hashtag: "",
    name: "",
    optionalFields: {
      counter: "signature",
    },
  };

  const { authenticationProperties, setAuthenticationProperties } = useContext(
    AuthenticationContext
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const { id } = useParams();
  const [initiative, setInitiative] = useState(defaultInitiative);
  const [isSaving, setIsSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [openContactForm, setOpenContactForm] = useState(false);
  const [status, setStatus] = useState("");
  const [tags, setTags] = useState([]);
  const { setTheme } = useContext(ThemeContext);
  const { trackEvent } = useMatomo();
  const dispatch = useDispatch(); //this hook gives us dispatch method
  const users = useSelector((state) => state.users);

  const isUserVerified = useMemo(() => {
    return users?.me?.status === "verified";
  }, [users?.me?.status]);
  
  const isUserLoggedIn = useMemo(() => {
    return Boolean(users?.me?.id);
  }, [users]);

  const MAX_LENGTHS = {
    title: 200,
  };

  const [counter, setCounter] = useState({ ...MAX_LENGTHS });

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setTheme("petition");

    trackEvent({
      category: "SAP",
      action: `SAPloaded`,
    });
  }, []);

  useEffect(() => {
    const getTags = async () => {
      await api.getTags({}, async (_status, data) => {
        if (!data || !Array.isArray(data)) {
          setTags([]);
          return false;
        }
        setTags(data);
      });
    };

    getTags();
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    (async () => {
      try {
        setIsSaving(true);
        setCurrentStep(1);
        await api.getInitiative(id, (_status, data) => {
          const initiative = data;
          if (!initiative.canEdit) {
            setStatus(404);
          }

          if (initiative?.tag?.id) {
            initiative.tag = initiative.tag.id;
          }

          if (initiative?.initiators?.[0]?.displayName) {
            initiative.displayName = initiative?.initiators?.[0]?.displayName;
          }

          if (initiative?.initiators?.[0]?.user) {
            initiative.user = initiative?.initiators?.[0]?.user;
          }

          delete initiative.initiators;
          setInitiative(initiative);
        });
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            setStatus(404);
          }
        } else if (error.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      } finally {
        setIsSaving(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (users.me?.id && initiative.initiators?.length == 0) {
      initiative.displayName = `${users.me.firstName || ""} ${
        users.me.lastName || ""
      }`;
      initiative.user = users.me.id;
    }
  }, [users]);

  const handleChange = async (e) => {
    let { name } = e.target;
    let value = e.target.value;

    if (MAX_LENGTHS[name]) {
      if (value.length > MAX_LENGTHS[name]) {
        return;
      }
      setCounter({
        ...counter,
        [name]: MAX_LENGTHS[name] - value.length,
      });
    }

    if ("targets" == name) {
      const validTargets = [];

      for (const target of value) {
        if (target.customOption) {
          await api.createActor(
            { name: target.name },
            async (_status, data) => {
              validTargets.push(data);
            }
          );
        } else if (
          target.id &&
          !validTargets.some((validTarget) => validTarget.id == target.id)
        ) {
          validTargets.push(target);
        }
      }
      value = validTargets;
    }

    setInitiative((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = async (e) => {
    if (!e.target.files[0]) {
      return;
    }
    setIsSaving(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("parent", initiative.id);

    await api
      .createAttachment(formData, async (_status, data) => {
        initiative.featuredImage = data;
        await save();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleNextStep = async (_e, step) => {
    if (!users?.me?.id) {
      setAuthenticationProperties({
        ...authenticationProperties,
        required: true,
      });
      return;
    }

    if (!isUserVerified) {
      return;
    }

    const [isValid, errors] = useValidateStep(
      currentStep,
      initiative,
      "petition-starten"
    );

    if (isValid) {
      try {
        if (currentStep != 0) {
          await save();
        }

        if (currentStep === 1) {
          console.log("Step 1 and sending", {
            category: "SAP",
            action: `SAPsaved`,
          });
          trackEvent({
            category: "SAP",
            action: `SAPsaved`,
          });
        }

        setCompletedSteps((prev) => [...prev, currentStep]);
        setCurrentStep((prev) => {
          return step || prev + 1;
        });
      } catch (e) {
        console.log("could not save", e);
      }
    }

    setFormErrors({ ...formErrors, ...errors });
  };

  const handlePreviousStep = (_e, step) => {
    setCurrentStep((prev) => {
      return step || prev - 1;
    });
  };

  const handleSubmit = async (e, action) => {
    e.preventDefault();

    const urls = {
      preview: `/${initiative.id}`,
      publish: `/petition-starten/${initiative.id}`,
    };

    const [isValid, errors] = useValidateStep(
      currentStep,
      initiative,
      "petition-starten"
    );

    if (isValid) {
      try {
        await save();
        const pathname = urls[action];
        history.push({
          pathname,
          state: { ...location.state, action: "draft", initiative },
        });
      } catch (err) {
        console.log(err);
        alert("Could not update");
      }
    } else {
      setFormErrors({ ...formErrors, ...errors });
    }
  };

  const save = async (params = {}) => {
    setIsSaving(true);

    if (initiative.featuredImage) {
      initiative.featuredImage = initiative.featuredImage.id;
    }

    if (initiative.displayName) {
      initiative.initiators = [
        {
          user: initiative.user,
          displayName: initiative.displayName,
        },
      ];
    }

    if (initiative.targets) {
      initiative.targets = initiative.targets.map((target) => ({
        ...target,
        _id: target.id,
      }));
    }

    return await api
      .upsertInitiative(
        initiative.id,
        { ...initiative, ...params },
        async (_status, data) => {
          if (data?.initiators?.[0]?.displayName) {
            data.displayName = data?.initiators?.[0]?.displayName;
          }

          if (data?.initiators?.[0]?.user) {
            data.user = data?.initiators?.[0]?.user;
          }
          setInitiative(data);
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const hasPreviousStep = currentStep > 1;
  const isLastPage = currentStep == 5;
  const isStartPage = currentStep < 1;

  if (status === 404) {
    return <NotFound />;
  }

  return (
    <>
      <UnverifiedEmailAlert verified={isUserLoggedIn && !isUserVerified} />

      {isStartPage && openContactForm && (
        <div className="container d-flex justify-content-center mt-5 position-relative p-0">
          <Contact setOpenContactForm={setOpenContactForm} />
        </div>
      )}

      <section className="petition-starten">
        {currentStep > 0 && (
          <Pagination
            currentStep={currentStep}
            handleNextStep={handleNextStep}
            completedSteps={completedSteps}
            handlePreviousStep={handlePreviousStep}
            isSaving={isSaving}
          />
        )}

        <form className="mt-6">
          <StepComponent
            counter={counter}
            formErrors={formErrors}
            handleNextStep={handleNextStep}
            handleChange={handleChange}
            handleImageUpload={handleImageUpload}
            initiative={initiative}
            isSaving={isSaving}
            maxLengths={MAX_LENGTHS}
            save={save}
            step={currentStep}
            tags={tags}
          />
          {isLastPage && (
            <section className="d-flex justify-content-end text-center text-md-end mb-4">
              <button
                className="btn btn-lg btn-primary me-4 me-md-0"
                disabled={isSaving}
                onClick={(e) => handleSubmit(e, "preview")}
                type="button"
                style={{ width: "12rem" }}
              >
                Vorschau
              </button>
            </section>
          )}
          <div
            className={`d-flex align-items-center justify-content-${
              hasPreviousStep ? "between" : "end"
            }`}
          >
            {hasPreviousStep && (
              <button
                className={`btn bg-transparent border-0 ${
                  isSaving ? "#EEEEEFF" : "text-primary"
                }`}
                disabled={isSaving}
                onClick={handlePreviousStep}
                type="button"
              >
                Zurück
              </button>
            )}
            <NextButton
              currentStep={currentStep}
              handleNextStep={handleNextStep}
              handleSubmit={handleSubmit}
              isSaving={isSaving}
              isDisabled={isUserLoggedIn && !isUserVerified}
            />
          </div>
        </form>
      </section>
      {isStartPage && (
        <div className="container d-flex justify-content-end mt-5 position-relative p-0">
          <ContactIcon
            openContactForm={openContactForm}
            setOpenContactForm={setOpenContactForm}
          />
        </div>
      )}
    </>
  );
};

const ContactIcon = ({ openContactForm, setOpenContactForm }) => {
  return (
    <div
      className={`contact-icon ${openContactForm ? "d-none" : "d-flex"}`}
      onClick={() => setOpenContactForm(true)}
    >
      <FontAwesomeIcon
        className="contact-icon-position text-white"
        icon={faMessage}
        size="3x"
      />
      <FontAwesomeIcon
        className="contact-icon-position text-primary"
        icon={faQuestion}
        size="xl"
      />
    </div>
  );
};

const UnverifiedEmailAlert = ({ verified }) => {
  return (
    <>
      {verified && (
        <div className="unverified-user ">
          <div className="hero-container">
            <div className="content">
              <div>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="text-primary"
                  size="5x"
                />
              </div>
              <div>
                <h1 className=" text-petition-primary mt-4 mb-3">
                  E-Mail-Bestätigung erforderlich
                </h1>
                <p className="fw-2 mb-4">
                  Um eine Petition zu starten, bestätige bitte Deine
                  E-Mail-Adresse mithilfe des zugesandten Bestätigungslinks.
                  Falls du die E-Mail nicht erhalten hast, überprüfe bitte
                  deinen Spam- oder Junk Ordner. Für Hilfe kontaktiere bitte
                  unser Support-Team.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const NextButton = ({
  currentStep,
  handleNextStep,
  handleSubmit,
  isSaving,
  isDisabled,
}) => {
  const properties = {
    text: { 0: "Jetzt Petition starten", 5: "Entwurf sichern" },
    action: { 5: (e) => handleSubmit(e, "publish") },
  };

  let buttonRef = useRef();

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      buttonRef?.current?.blur();
    }

    return () => {
      unmounted = true;
    };
  }, [currentStep]);

  const disabled =
    (properties.text[currentStep] != "Jetzt Petition starten"
      ? isSaving
      : "") || isDisabled;

  return (
    <button
      className={`btn btn-lg me-4 me-md-0 ${
        currentStep == 5 ? "text-primary btn-outline-secondary" : "btn-primary"
      }`}
      type="button"
      disabled={disabled}
      onClick={properties.action[currentStep] || handleNextStep}
      ref={buttonRef}
    >
      {properties.text[currentStep] || "Weiter"}
    </button>
  );
};

export default Index;
