import React, { useContext, useEffect, useState } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import api from "../../../helpers/api";
import DonationBanner from "../DonationBanner";
import donationInitiativeImage from "../../../styles/images/banners/donation.svg";
import donationPetitionImage from "../../../styles/images/donate.svg";
import getDonationUrl from "../../../helpers/get-donation-url";
import { ThemeContext } from "../../../ThemeContext";
import { goToSharePage, SignableSteps } from "../navigation";


const Donation = () => {
  const CONTENT = {
    initiative: {
      donationImage: donationInitiativeImage,
      title: "Briefmarken für direkte Demokratie",
      content: (
        <>
          <p class="fw-bold">
            Hilf uns unsere Portokasse aufzufüllen damit noch mehr Menschen die
            Chance haben mitzuwirken. Können Sie mit einer Spende mithelfen, die
            Briefmarken für die nächsten Wochen zu finanzieren?
          </p>
          <p>
            Die Teilnahme bei innn.it ist sowohl für die Initiativen als auch
            für die Nutzer:innen kostenlos. Auch die Portokosten werden komplett
            von Change.org e.V. übernommen, dem Trägerverein der Plattform. Hier
            kannst du den Change.org e.V. mit einer Spende unterstützen:
          </p>
        </>
      ),
    },
    petition: {
      donationImage: donationPetitionImage,
      title: "Engagier Dich gemeinsam mit uns!",
      content: (
        <>
          Schon ein kleiner regelmäßiger Beitrag hat{" "}
          <strong>große Wirkung</strong>. Mit Deiner Förderung trägst Du dazu
          bei, dass wir innn.it kontinuierlich ausbauen, Initiator*innen von
          Petitionen begleiten und Kampagnen Gehör verschaffen können!{" "}
          <strong>Schließt Du Dich uns an?</strong>
        </>
      ),
    },
  };

  const location = useLocation();
  let history = useHistory();
  let { id } = useParams();
  const { trackEvent } = useMatomo();
  const [isBannerDisplayed, setIsBannerDisplayed] = useState(false);

  const [initiative, setInitiative] = useState({});
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (location.state?.initiative) {
      setInitiative(location.state.initiative);
      setTheme(location.state.initiative.type || "petition");
      return;
    }

    api.getInitiative(id, (_status, data) => {
      setInitiative(data);
      setTheme(data.type || "petition");
    });
  }, [location.state, id]);

  const handleClick = (event, url) => {
    event.preventDefault();
    trackEvent({
      category: initiative.type,
      action: "goToDonation",
    });
    window.open(url, "_blank");
  };

  return (
    <div className="donation">
      <DonationBanner initiative={initiative} onClose={()=> setIsBannerDisplayed(true)} />
      {/* <div className="donation-header">
        <div className="donation-header-container d-flex justify-content-between">
          <h3 className="donation-header-text text-secondary">
            Grundeinkommen für alle
          </h3>
          <Icon name={"icon-close"} />
        </div>
      </div> */}
      <div className="container pt-5">
        <div className="container-narrow-sm">
          {initiative && <SignableSteps currentStep={2} pageCount={3} signable={initiative} isBannerDisplayed={isBannerDisplayed} />}
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h2 className="text-primary pb-5">
              {CONTENT?.[initiative.type]?.title}
            </h2>
            <div>{CONTENT?.[initiative.type]?.content}</div>
            <p className="pt-5 d-grid gap-2 col-md-8">
              <a
                className="btn btn-primary"
                id="button-addon2"
                onClick={(event) =>
                  handleClick(
                    event,
                    getDonationUrl(
                      initiative.type,
                      "post_signature",
                      initiative.id
                    )
                  )
                }
              >
                Zur Spendenseite
              </a>
              <button
                className="btn btn-outline"
                type="submit"
                onClick={() => goToSharePage(initiative, history, location, isBannerDisplayed, trackEvent)}
              >
                Nein, aber ich teile diese Petition
              </button>
            </p>
          </div>
          <div className="align-items-center col-12 col-md-6 d-flex justify-content-center">
            <img
              className="img-fluid"
              src={CONTENT?.[initiative.type]?.donationImage}
              alt="innn.it-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export default Donation;
