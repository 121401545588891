import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import Steps from "../../assets/elements/Steps";

export const goToDonationPage = (signable, history, location, isBannerDisplayed, trackEvent) => {
  if (!signable?.id) {
    return false;
  }

  history.push({
    pathname: `/${signable.id}/donation`,
    state: { ...location.state, isBannerDisplayed }
  });

  window.scrollTo(0, 0);

  trackEvent({
    category: "document",
    action: "startDonation",
  });
}

export const goToSharePage = (signable, history, location, isBannerDisplayed, trackEvent) => {
  if (!signable?.id) {
    return false;
  }

  history.push({
    pathname: `/${signable.id}/share`,
    state: { ...location.state, isBannerDisplayed }
  });

  window.scrollTo(0, 0);

  trackEvent({
    category: signable.type,
    action: "startShare",
  });
};

export const goToSignablePage = (signable, history, location, isBannerDisplayed, trackEvent) => {
  if (!signable?.id) {
    return false;
  }

  history.push({
    pathname: `/${signable.id}`,
  });

  window.scrollTo(0, 0);
}

export const SignableSteps = ({ currentStep, pageCount, signable, isBannerDisplayed }) => {
  const history = useHistory();
  const location = useLocation();
  const { trackEvent } = useMatomo();

  const actions = {
    1: () => goToSignablePage(signable, history, location, isBannerDisplayed, trackEvent),
    2: () => goToDonationPage(signable, history, location, isBannerDisplayed, trackEvent),
    3: () => goToSharePage(signable, history, location, isBannerDisplayed, trackEvent),
  }

  return (
    <>
      {signable && <Steps currentStep={currentStep} pageCount={pageCount} actions={actions} />}
    </>
  );
}
