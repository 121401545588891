import React from "react";
import Steps from "./Steps";

const Pagination = () => {
  return (
    <div>
      <div className="petition">
        <div className="text-gray-5 mb-5" style={{marginLeft: "10rem"}}>Petitions</div>
        <Steps pageCount={5} currentStep={1}/>
        <Steps pageCount={5} currentStep={2}/>
        <Steps pageCount={5} currentStep={3}/>
        <Steps pageCount={5} currentStep={4}/>
        <Steps pageCount={5} currentStep={5}/>
        <Steps pageCount={5} currentStep={6}/>
      </div>
      <div className="initiative">
      <div className="text-gray-5 mb-3" style={{marginLeft: "10rem"}}>Initiatives</div>
        <Steps pageCount={5} currentStep={1}/>
        <Steps pageCount={5} currentStep={2}/>
        <Steps pageCount={5} currentStep={3}/>
        <Steps pageCount={5} currentStep={4}/>
        <Steps pageCount={5} currentStep={5}/>
        <Steps pageCount={5} currentStep={6}/>
      </div>
    </div>
  )
};

export default Pagination;
