import React, { useState, useEffect } from "react";


const Steps = ({ actions, currentStep, onClick, pageCount }) => {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps(Array.from({ length: pageCount }, (_value, index) => index + 1));
  }, [pageCount, setSteps]);

  return (
    <nav aria-label="Steps" className="mb-5">
      <ul className="pagination">
        {
          steps.map((step) =>
            <Step
              key={'step' + step.toString()}
              active={step === currentStep}
              checked={currentStep > step}
              step={step}
              onClick={actions?.[step] || onClick} />
          )
        }
      </ul>
    </nav>
  );
};


const Step = ({ active, checked, onClick, step }) => {
  let className ="page-item";

  const handleClick = () => {
    
    if (onClick) {
      onClick();
    }
  };

  if (active) {
    className += " active"
  }

  if (checked) {
    className += " checked"
  }

  return (
    <li className={className}>
      <a className="page-link" href="#" onClick={handleClick}>{checked ? "" : step}</a>
    </li>
  )
};

export default Steps;
